import React, { ReactElement } from "react";

export default function ImproveCustomerSatisfactionIcon(): ReactElement {
    return (
        <svg
            width="162"
            height="74"
            viewBox="0 0 162 74"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_7_1013_0)">
                <g opacity="0.5">
                    <path
                        opacity="0.5"
                        d="M45.7388 9.0007H44.7934C44.3013 9.01402 43.8338 9.2195 43.4905 9.57339C43.1471 9.92729 42.955 10.4017 42.955 10.8955C42.955 11.3894 43.1471 11.8638 43.4905 12.2177C43.8338 12.5716 44.3013 12.7771 44.7934 12.7904H45.7388C46.2309 12.7771 46.6984 12.5716 47.0418 12.2177C47.3852 11.8638 47.5773 11.3894 47.5773 10.8955C47.5773 10.4017 47.3852 9.92729 47.0418 9.57339C46.6984 9.2195 46.2309 9.01402 45.7388 9.0007V9.0007Z"
                        fill="#B2B2B2"
                    />
                    <path
                        opacity="0.5"
                        d="M2.78248 16.58H1.83846C1.34636 16.5934 0.878874 16.7988 0.535503 17.1527C0.192133 17.5066 0 17.981 0 18.4749C0 18.9687 0.192133 19.4431 0.535503 19.797C0.878874 20.1509 1.34636 20.3564 1.83846 20.3697H2.78248C3.0348 20.3765 3.28593 20.3326 3.52103 20.2404C3.75613 20.1483 3.97045 20.0098 4.15132 19.8332C4.33219 19.6565 4.47595 19.4453 4.57411 19.212C4.67227 18.9788 4.72285 18.7281 4.72285 18.4749C4.72285 18.2216 4.67227 17.971 4.57411 17.7377C4.47595 17.5044 4.33219 17.2932 4.15132 17.1166C3.97045 16.94 3.75613 16.8015 3.52103 16.7093C3.28593 16.6172 3.0348 16.5732 2.78248 16.58V16.58Z"
                        fill="#B2B2B2"
                    />
                    <path
                        opacity="0.5"
                        d="M43.9265 16.58H36.296C36.0437 16.5869 35.7925 16.5429 35.5574 16.4507C35.3223 16.3586 35.108 16.2201 34.9271 16.0435C34.7463 15.8669 34.6025 15.6557 34.5043 15.4224C34.4062 15.1891 34.3556 14.9384 34.3556 14.6852C34.3556 14.432 34.4062 14.1813 34.5043 13.948C34.6025 13.7147 34.7463 13.5035 34.9271 13.3269C35.108 13.1503 35.3223 13.0118 35.5574 12.9196C35.7925 12.8275 36.0437 12.7835 36.296 12.7904H39.319C39.8111 12.777 40.2786 12.5716 40.622 12.2177C40.9654 11.8638 41.1575 11.3894 41.1575 10.8955C41.1575 10.4017 40.9654 9.92728 40.622 9.57338C40.2786 9.21948 39.8111 9.01401 39.319 9.00069H15.7831C15.5308 8.99386 15.2797 9.03782 15.0446 9.12998C14.8095 9.22213 14.5951 9.36061 14.4143 9.53724C14.2334 9.71387 14.0896 9.92506 13.9915 10.1583C13.8933 10.3916 13.8427 10.6423 13.8427 10.8955C13.8427 11.1488 13.8933 11.3994 13.9915 11.6327C14.0896 11.866 14.2334 12.0772 14.4143 12.2538C14.5951 12.4304 14.8095 12.5689 15.0446 12.6611C15.2797 12.7532 15.5308 12.7972 15.7831 12.7904H19.7928C20.2849 12.8037 20.7524 13.0092 21.0957 13.3631C21.4391 13.7169 21.6313 14.1913 21.6313 14.6852C21.6313 15.1791 21.4391 15.6534 21.0957 16.0073C20.7524 16.3612 20.2849 16.5667 19.7928 16.58H8.25704C7.76494 16.5933 7.29745 16.7988 6.95408 17.1527C6.61071 17.5066 6.41858 17.981 6.41858 18.4749C6.41858 18.9687 6.61071 19.4431 6.95408 19.797C7.29745 20.1509 7.76494 20.3564 8.25704 20.3697H43.9237C44.4158 20.3564 44.8833 20.1509 45.2267 19.797C45.57 19.4431 45.7622 18.9687 45.7622 18.4749C45.7622 17.981 45.57 17.5066 45.2267 17.1527C44.8833 16.7988 44.4158 16.5933 43.9237 16.58H43.9265Z"
                        fill="#B2B2B2"
                    />
                </g>
                <g opacity="0.5">
                    <path
                        opacity="0.5"
                        d="M91.2412 67.5805L92.3937 67.5805C92.9936 67.5646 93.5635 67.3192 93.9821 66.8965C94.4007 66.4737 94.6349 65.9071 94.6349 65.3172C94.6349 64.7273 94.4007 64.1607 93.9821 63.738C93.5635 63.3153 92.9936 63.0699 92.3937 63.054L91.2412 63.054C90.6413 63.0699 90.0714 63.3153 89.6528 63.738C89.2342 64.1607 89 64.7273 89 65.3172C89 65.9071 89.2342 66.4737 89.6528 66.8965C90.0714 67.3192 90.6413 67.5646 91.2412 67.5805V67.5805Z"
                        fill="#B2B2B2"
                    />
                    <path
                        opacity="0.5"
                        d="M143.608 58.5274L144.759 58.5274C145.359 58.5115 145.929 58.266 146.347 57.8433C146.766 57.4206 147 56.854 147 56.2641C147 55.6742 146.766 55.1076 146.347 54.6849C145.929 54.2622 145.359 54.0167 144.759 54.0008L143.608 54.0008C143.3 53.9927 142.994 54.0452 142.708 54.1552C142.421 54.2653 142.16 54.4307 141.939 54.6417C141.719 54.8527 141.544 55.1049 141.424 55.3836C141.304 55.6622 141.243 55.9616 141.243 56.2641C141.243 56.5666 141.304 56.866 141.424 57.1446C141.544 57.4233 141.719 57.6755 141.939 57.8865C142.16 58.0975 142.421 58.2629 142.708 58.3729C142.994 58.483 143.3 58.5355 143.608 58.5274V58.5274Z"
                        fill="#B2B2B2"
                    />
                    <path
                        opacity="0.5"
                        d="M93.4507 58.5274L102.753 58.5274C103.06 58.5192 103.367 58.5717 103.653 58.6818C103.94 58.7919 104.201 58.9573 104.422 59.1682C104.642 59.3792 104.817 59.6315 104.937 59.9101C105.057 60.1888 105.118 60.4882 105.118 60.7906C105.118 61.0931 105.057 61.3925 104.937 61.6712C104.817 61.9498 104.642 62.2021 104.422 62.413C104.201 62.624 103.94 62.7894 103.653 62.8995C103.367 63.0096 103.06 63.0621 102.753 63.0539L99.0675 63.0539C98.4676 63.0698 97.8977 63.3153 97.4791 63.738C97.0605 64.1607 96.8263 64.7273 96.8263 65.3172C96.8263 65.9071 97.0605 66.4737 97.4791 66.8964C97.8977 67.3191 98.4676 67.5646 99.0675 67.5805L127.759 67.5805C128.067 67.5886 128.373 67.5361 128.66 67.426C128.946 67.316 129.208 67.1506 129.428 66.9396C129.649 66.7286 129.824 66.4764 129.944 66.1977C130.063 65.9191 130.125 65.6197 130.125 65.3172C130.125 65.0147 130.063 64.7153 129.944 64.4367C129.824 64.158 129.649 63.9058 129.428 63.6948C129.208 63.4838 128.946 63.3184 128.66 63.2083C128.373 63.0983 128.067 63.0458 127.759 63.0539L122.871 63.0539C122.271 63.038 121.702 62.7926 121.283 62.3699C120.864 61.9472 120.63 61.3806 120.63 60.7906C120.63 60.2007 120.864 59.6341 121.283 59.2114C121.702 58.7887 122.271 58.5433 122.871 58.5274L136.934 58.5274C137.534 58.5115 138.104 58.266 138.523 57.8433C138.941 57.4206 139.175 56.854 139.175 56.2641C139.175 55.6742 138.941 55.1076 138.523 54.6849C138.104 54.2622 137.534 54.0167 136.934 54.0008L93.4541 54.0008C92.8542 54.0167 92.2843 54.2622 91.8657 54.6849C91.4471 55.1076 91.2129 55.6742 91.2129 56.2641C91.2129 56.854 91.4471 57.4206 91.8657 57.8433C92.2843 58.266 92.8542 58.5115 93.4541 58.5274L93.4507 58.5274Z"
                        fill="#B2B2B2"
                    />
                </g>
                <path
                    d="M14 24H22"
                    stroke="#129188"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18 28V20"
                    stroke="#129188"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M161 54H159.35M143.14 63H124.342M115.149 63H116.617M151.866 54H106"
                    stroke="#129188"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <circle cx="75.5" cy="35.5" r="19.5" fill="#E4F2EC" />
                <path
                    d="M104.259 29.5218C103.284 24.7425 101.142 20.2833 98.0255 16.5471L91.777 25.4209C92.9447 27.3256 93.7715 29.4219 94.2195 31.6144C96.3519 41.9827 89.7333 52.1277 79.4371 54.2729C70.2447 56.1892 61.2294 51.0812 57.8852 42.5855L60.4769 42.0293L56.7721 38.4877V38.4851L50.6073 32.5907V32.5946L45.1265 45.3194L47.7674 44.7538C52.3581 58.8037 66.7888 67.4525 81.516 64.3828C97.355 61.0807 107.54 45.473 104.259 29.5218Z"
                    fill="#129188"
                />
                <path
                    d="M69.6414 6.61902C59.4191 8.75052 51.5548 16.005 48.1431 25.1698L56.4475 21.0856L57.4851 29.5282C59.5435 23.2227 64.8017 18.1695 71.7181 16.7276C74.2545 16.1956 76.8722 16.192 79.4101 16.7172C81.9479 17.2423 84.3521 18.285 86.4746 19.7811L92.4037 11.3604C86.0217 6.85091 77.8774 4.89995 69.6414 6.61902Z"
                    fill="#129188"
                />
                <path
                    d="M100.218 6.84661C99.7184 6.49198 99.0999 6.35087 98.4974 6.45413C97.895 6.5574 97.3577 6.89662 97.0029 7.39766L93.5907 12.2443L87.6558 20.6792L75.2279 38.3289L65.0647 28.0945C64.6129 27.6425 64.002 27.3888 63.3652 27.3888C62.7284 27.3888 62.1174 27.6425 61.6657 28.0945V28.0945C61.2167 28.5493 60.9646 29.1645 60.9646 29.8059C60.9646 30.4472 61.2167 31.0625 61.6657 31.5172L73.562 43.4968C73.7346 43.6709 73.9328 43.8172 74.1496 43.9306C74.6492 44.2745 75.2628 44.4074 75.8586 44.3006C76.4544 44.1939 76.9849 43.8562 77.3361 43.3599L90.8928 24.1036L97.0199 15.4026L100.765 10.0828C101.117 9.57997 101.257 8.95741 101.154 8.35106C101.052 7.74472 100.715 7.20386 100.218 6.84661V6.84661Z"
                    fill="#129188"
                />
            </g>
            <defs>
                <clipPath id="clip0_7_1013_0">
                    <rect width="162" height="74" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
