import React, { ReactElement } from "react";

export default function ImproveDriverSafetyIcon(): ReactElement {
    return (
        <svg
            width="162"
            height="74"
            viewBox="0 0 162 74"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_7_1013_1)">
                <g opacity="0.5">
                    <path
                        opacity="0.5"
                        d="M45.7388 13.0007H44.7934C44.3013 13.014 43.8338 13.2195 43.4905 13.5734C43.1471 13.9273 42.955 14.4017 42.955 14.8955C42.955 15.3894 43.1471 15.8638 43.4905 16.2177C43.8338 16.5716 44.3013 16.7771 44.7934 16.7904H45.7388C46.2309 16.7771 46.6984 16.5716 47.0418 16.2177C47.3852 15.8638 47.5773 15.3894 47.5773 14.8955C47.5773 14.4017 47.3852 13.9273 47.0418 13.5734C46.6984 13.2195 46.2309 13.014 45.7388 13.0007V13.0007Z"
                        fill="#B2B2B2"
                    />
                    <path
                        opacity="0.5"
                        d="M2.78248 20.58H1.83846C1.34636 20.5934 0.878874 20.7988 0.535503 21.1527C0.192133 21.5066 0 21.981 0 22.4749C0 22.9687 0.192133 23.4431 0.535503 23.797C0.878874 24.1509 1.34636 24.3564 1.83846 24.3697H2.78248C3.0348 24.3765 3.28593 24.3326 3.52103 24.2404C3.75613 24.1483 3.97045 24.0098 4.15132 23.8332C4.33219 23.6565 4.47595 23.4453 4.57411 23.212C4.67227 22.9788 4.72285 22.7281 4.72285 22.4749C4.72285 22.2216 4.67227 21.971 4.57411 21.7377C4.47595 21.5044 4.33219 21.2932 4.15132 21.1166C3.97045 20.94 3.75613 20.8015 3.52103 20.7093C3.28593 20.6172 3.0348 20.5732 2.78248 20.58V20.58Z"
                        fill="#B2B2B2"
                    />
                    <path
                        opacity="0.5"
                        d="M43.9265 20.58H36.296C36.0437 20.5869 35.7925 20.5429 35.5574 20.4507C35.3223 20.3586 35.108 20.2201 34.9271 20.0435C34.7463 19.8669 34.6025 19.6557 34.5043 19.4224C34.4062 19.1891 34.3556 18.9384 34.3556 18.6852C34.3556 18.432 34.4062 18.1813 34.5043 17.948C34.6025 17.7147 34.7463 17.5035 34.9271 17.3269C35.108 17.1503 35.3223 17.0118 35.5574 16.9196C35.7925 16.8275 36.0437 16.7835 36.296 16.7904H39.319C39.8111 16.777 40.2786 16.5716 40.622 16.2177C40.9654 15.8638 41.1575 15.3894 41.1575 14.8955C41.1575 14.4017 40.9654 13.9273 40.622 13.5734C40.2786 13.2195 39.8111 13.014 39.319 13.0007H15.7831C15.5308 12.9939 15.2797 13.0378 15.0446 13.13C14.8095 13.2221 14.5951 13.3606 14.4143 13.5372C14.2334 13.7139 14.0896 13.9251 13.9915 14.1583C13.8933 14.3916 13.8427 14.6423 13.8427 14.8955C13.8427 15.1488 13.8933 15.3994 13.9915 15.6327C14.0896 15.866 14.2334 16.0772 14.4143 16.2538C14.5951 16.4304 14.8095 16.5689 15.0446 16.6611C15.2797 16.7532 15.5308 16.7972 15.7831 16.7904H19.7928C20.2849 16.8037 20.7524 17.0092 21.0957 17.3631C21.4391 17.7169 21.6313 18.1913 21.6313 18.6852C21.6313 19.1791 21.4391 19.6534 21.0957 20.0073C20.7524 20.3612 20.2849 20.5667 19.7928 20.58H8.25704C7.76494 20.5933 7.29745 20.7988 6.95408 21.1527C6.61071 21.5066 6.41858 21.981 6.41858 22.4749C6.41858 22.9687 6.61071 23.4431 6.95408 23.797C7.29745 24.1509 7.76494 24.3564 8.25704 24.3697H43.9237C44.4158 24.3564 44.8833 24.1509 45.2267 23.797C45.57 23.4431 45.7622 22.9687 45.7622 22.4749C45.7622 21.981 45.57 21.5066 45.2267 21.1527C44.8833 20.7988 44.4158 20.5933 43.9237 20.58H43.9265Z"
                        fill="#B2B2B2"
                    />
                </g>
                <g opacity="0.5">
                    <path
                        opacity="0.5"
                        d="M91.2412 71.5805L92.3937 71.5805C92.9936 71.5646 93.5635 71.3192 93.9821 70.8965C94.4007 70.4737 94.6349 69.9071 94.6349 69.3172C94.6349 68.7273 94.4007 68.1607 93.9821 67.738C93.5635 67.3153 92.9936 67.0699 92.3937 67.054L91.2412 67.054C90.6413 67.0699 90.0714 67.3153 89.6528 67.738C89.2342 68.1607 89 68.7273 89 69.3172C89 69.9071 89.2342 70.4737 89.6528 70.8965C90.0714 71.3192 90.6413 71.5646 91.2412 71.5805V71.5805Z"
                        fill="#B2B2B2"
                    />
                    <path
                        opacity="0.5"
                        d="M143.608 62.5274L144.759 62.5274C145.359 62.5115 145.929 62.266 146.347 61.8433C146.766 61.4206 147 60.854 147 60.2641C147 59.6742 146.766 59.1076 146.347 58.6849C145.929 58.2622 145.359 58.0167 144.759 58.0008L143.608 58.0008C143.3 57.9927 142.994 58.0452 142.708 58.1552C142.421 58.2653 142.16 58.4307 141.939 58.6417C141.719 58.8527 141.544 59.1049 141.424 59.3836C141.304 59.6622 141.243 59.9616 141.243 60.2641C141.243 60.5666 141.304 60.866 141.424 61.1446C141.544 61.4233 141.719 61.6755 141.939 61.8865C142.16 62.0975 142.421 62.2629 142.708 62.3729C142.994 62.483 143.3 62.5355 143.608 62.5274V62.5274Z"
                        fill="#B2B2B2"
                    />
                    <path
                        opacity="0.5"
                        d="M93.4507 62.5274L102.753 62.5274C103.06 62.5192 103.367 62.5717 103.653 62.6818C103.94 62.7919 104.201 62.9573 104.422 63.1682C104.642 63.3792 104.817 63.6315 104.937 63.9101C105.057 64.1888 105.118 64.4882 105.118 64.7906C105.118 65.0931 105.057 65.3925 104.937 65.6712C104.817 65.9498 104.642 66.2021 104.422 66.413C104.201 66.624 103.94 66.7894 103.653 66.8995C103.367 67.0096 103.06 67.0621 102.753 67.0539L99.0675 67.0539C98.4676 67.0698 97.8977 67.3153 97.4791 67.738C97.0605 68.1607 96.8263 68.7273 96.8263 69.3172C96.8263 69.9071 97.0605 70.4737 97.4791 70.8964C97.8977 71.3191 98.4676 71.5646 99.0675 71.5805L127.759 71.5805C128.067 71.5886 128.373 71.5361 128.66 71.426C128.946 71.316 129.208 71.1506 129.428 70.9396C129.649 70.7286 129.824 70.4764 129.944 70.1977C130.063 69.9191 130.125 69.6197 130.125 69.3172C130.125 69.0147 130.063 68.7153 129.944 68.4367C129.824 68.158 129.649 67.9058 129.428 67.6948C129.208 67.4838 128.946 67.3184 128.66 67.2083C128.373 67.0983 128.067 67.0458 127.759 67.0539L122.871 67.0539C122.271 67.038 121.702 66.7926 121.283 66.3699C120.864 65.9472 120.63 65.3806 120.63 64.7906C120.63 64.2007 120.864 63.6341 121.283 63.2114C121.702 62.7887 122.271 62.5433 122.871 62.5274L136.934 62.5274C137.534 62.5115 138.104 62.266 138.523 61.8433C138.941 61.4206 139.175 60.854 139.175 60.2641C139.175 59.6742 138.941 59.1076 138.523 58.6849C138.104 58.2622 137.534 58.0167 136.934 58.0008L93.4541 58.0008C92.8542 58.0167 92.2843 58.2622 91.8657 58.6849C91.4471 59.1076 91.2129 59.6742 91.2129 60.2641C91.2129 60.854 91.4471 61.4206 91.8657 61.8433C92.2843 62.266 92.8542 62.5115 93.4541 62.5274L93.4507 62.5274Z"
                        fill="#B2B2B2"
                    />
                </g>
                <path
                    d="M14 28H22"
                    stroke="#129188"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18 32V24"
                    stroke="#129188"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M102.383 11.3682C102.383 11.3682 102.209 19.991 101.798 26.1837C101.577 29.5035 101.503 32.4759 100.386 39.4166C99.1964 46.8208 98.2605 50.8705 94.4031 55.6528C88.6429 62.7938 77.1364 68.1962 77.1364 68.1962C77.1364 68.1962 65.6308 62.7938 59.8699 55.6528C56.0126 50.871 55.0767 46.8213 53.8863 39.4166C52.7707 32.4764 52.6948 29.5035 52.4748 26.1837C52.0639 19.9902 51.8899 11.3682 51.8899 11.3682L77.1364 5.46494L102.383 11.3682Z"
                    fill="#E4F2EC"
                />
                <path
                    d="M84.8034 33.8079L76.4299 42.2012C76.279 42.3524 76.0999 42.4724 75.9027 42.5542C75.7056 42.6361 75.4943 42.6782 75.2809 42.6781C75.0675 42.6782 74.8562 42.6361 74.659 42.5542C74.4618 42.4724 74.2827 42.3524 74.1318 42.2012L69.307 37.3648C69.0222 37.0792 68.8621 36.6919 68.8621 36.2881C68.8621 35.8843 69.0222 35.497 69.307 35.2114L69.4569 35.0611C69.7418 34.7756 70.1282 34.6152 70.5311 34.6152C70.9339 34.6152 71.3203 34.7756 71.6052 35.0611L75.2819 38.7466L82.5059 31.5052C82.7909 31.2196 83.1773 31.0592 83.5802 31.0592C83.9831 31.0592 84.3695 31.2196 84.6544 31.5052L84.7239 31.5748L84.8043 31.6555C85.0892 31.941 85.2493 32.3283 85.2493 32.7322C85.2493 33.136 85.0892 33.5233 84.8043 33.8089L84.8034 33.8079ZM77.1364 24.3286C70.2486 24.3286 64.6647 29.9257 64.6647 36.8303C64.6647 43.735 70.2486 49.332 77.1364 49.332C84.0243 49.332 89.6082 43.7347 89.6082 36.8303C89.6082 29.926 84.0245 24.3286 77.1364 24.3286V24.3286Z"
                    fill="#129188"
                />
                <path
                    d="M77.1364 73C76.9231 73 76.7125 72.9529 76.5195 72.8621C76.3662 72.79 72.7156 71.064 68.3123 68.3327C65.716 66.7221 63.4011 65.0968 61.4321 63.5013C58.9279 61.4722 56.967 59.4755 55.6047 57.5669C53.8173 55.0638 52.4413 52.185 51.3981 48.7657C50.5168 45.8787 49.8571 42.5722 49.3217 38.3636C48.4225 31.2962 48.1172 27.2388 47.9016 23.9906C47.4934 17.8358 47.3157 9.19482 47.314 9.10821C47.3074 8.77479 47.4152 8.44921 47.6194 8.18592C47.8236 7.92263 48.1118 7.73759 48.4358 7.66174L76.807 1.03793C77.0237 0.987357 77.2491 0.987357 77.4658 1.03793L105.838 7.66224C106.162 7.73803 106.45 7.92305 106.654 8.18635C106.859 8.44964 106.967 8.77525 106.96 9.1087C106.958 9.19531 106.781 17.8363 106.372 23.9911C106.157 27.2393 105.851 31.2969 104.952 38.3641C104.417 42.573 103.757 45.8782 102.876 48.7662C101.833 52.1855 100.457 55.0643 98.6691 57.5674C97.3061 59.476 95.3459 61.4727 92.8407 63.5018C90.8714 65.0973 88.5575 66.7226 85.9605 68.3332C81.5572 71.064 77.9069 72.79 77.7535 72.8621C77.5604 72.9529 77.3497 73 77.1364 73ZM50.2426 10.2275C50.3061 12.7972 50.4826 19.0531 50.7974 23.7974C51.0101 27.0032 51.3117 31.0087 52.2005 37.9955C52.7148 42.0365 53.3415 45.1886 54.1733 47.9149C55.123 51.0275 56.3641 53.6307 57.9642 55.8732C59.1726 57.5654 60.9486 59.3667 63.2428 61.2277C65.1137 62.7445 67.3237 64.2968 69.8114 65.842C73.0445 67.849 75.9186 69.3246 77.1361 69.9284C78.3537 69.3246 81.2278 67.849 84.4609 65.842C86.9486 64.2973 89.1586 62.745 91.0295 61.2277C93.3237 59.3672 95.0997 57.5659 96.3081 55.8732C97.9097 53.6307 99.1498 51.0275 100.099 47.9149C100.931 45.1888 101.558 42.0368 102.072 37.9955C102.961 31.0087 103.262 27.0034 103.475 23.7974C103.79 19.0531 103.967 12.7972 104.03 10.2275L77.1364 3.94961L50.2426 10.2275Z"
                    fill="#129188"
                />
                <path
                    d="M161 58H159.35M143.14 67H124.342M115.149 67H116.617M151.866 58H106"
                    stroke="#129188"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_7_1013_1">
                    <rect width="162" height="74" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
