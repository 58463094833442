import React, { ReactElement } from "react";
import colors from "@styles/variables/businessphonepricesVariables.module.scss";

import ImproveDriverSafetyIcon from "../icons/improveDriverSafety";
import LowerFuelConsumptionIcon from "../icons/lowerFuelConsumption";
import UpdatesAndInsightsIcon from "../icons/updatesAndInsights";
import ImproveVehicleMaintenanceIcon from "../icons/improveVehicleMaintenance";
import ImproveCustomerSatisfactionIcon from "../icons/improveCustomerSatisfaction";
import ImproveRoutePlanningIcon from "../icons/improveRoutePlanning";
import FeatureList from "@components/shared/featureList";

const whyUsList = [
    {
        title: "Improve Driver Safety",
        description:
            "Driver safety must be of paramount importance to fleet managers, and so, a driver’s performance and behavior on the road are the key focus of a fleet management system.",
        icon: <ImproveDriverSafetyIcon />,
    },
    {
        title: "Lower Fuel Consumption",
        description:
            "The ever-fluctuating fuel prices make tracking and budgeting for fuel costs a challenge. Fleet tracking systems make it easier to control fuel costs.",
        icon: <LowerFuelConsumptionIcon />,
    },
    {
        title: "Updates and Insights",
        description:
            "The GPS vehicle tracking software allows fleet managers to track their fleet in near real-time and redirect vehicles when needed.",
        icon: <UpdatesAndInsightsIcon />,
    },
    {
        title: "Improve Vehicle Maintenance",
        description:
            "Fleet maintenance and vehicle life-cycles can often be areas that put a heavy strain on a business’ finances. This is where your tracking system will come in handy.",
        icon: <ImproveVehicleMaintenanceIcon />,
    },
    {
        title: "Improve Customer Satisfaction",
        description:
            "Using fleet management software, fleet managers can simplify bookings and create appointment reminders.",
        icon: <ImproveCustomerSatisfactionIcon />,
    },
    {
        title: "Improve Route Planning",
        description:
            "Fleet tracking systems help businesses plan the routes, directions, times, and distribution of their fleet efficiently.",
        icon: <ImproveRoutePlanningIcon />,
    },
];

export default function WhyUs(): ReactElement {
    return (
        <FeatureList
            featureList={whyUsList}
            mainTitle="Benefits of Fleet Tracking"
            classes={{
                mainTitleClasses:
                    "text-center text-xl lg:text-3xl mb-16 font-bold",
                innerSectionClasses:
                    "max-w-7xl mx-auto block px-4vw lg:px-4 lg:flex flex-wrap justify-center",
                oneBoxClasses: "text-center mb-12 lg:mb-20 w-full lg:w-1/3",
                iconClasses: "children:mx-auto",
                titleClasses: "font-medium text-lg lg:text-xl mt-6",
                descriptionClasses:
                    "font-light text-sm text-center w-4/5 mx-auto mt-2",
            }}
            colors={{
                bgColor: ` linear-gradient(180deg,rgba(252, 252, 252, 0) 0%,#f3f5f5 100%) `,
                descriptionColor: colors.bankVault,
            }}
        />
    );
}
