import React, { ReactElement } from "react";

export default function ImproveRoutePlanningIcon(): ReactElement {
    return (
        <svg
            width="162"
            height="74"
            viewBox="0 0 162 74"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity="0.5">
                <path
                    opacity="0.5"
                    d="M144.821 12.5273L145.718 12.5273C146.185 12.5149 146.629 12.3237 146.956 11.9944C147.282 11.665 147.464 11.2236 147.464 10.764C147.464 10.3044 147.282 9.86293 146.956 9.53359C146.629 9.20425 146.185 9.01304 145.718 9.00064L144.821 9.00064C144.582 8.99429 144.343 9.03519 144.12 9.12095C143.897 9.20671 143.693 9.33558 143.521 9.49995C143.35 9.66432 143.213 9.86086 143.12 10.078C143.027 10.2951 142.979 10.5283 142.979 10.764C142.979 10.9996 143.027 11.2329 143.12 11.45C143.213 11.6671 143.35 11.8636 143.521 12.028C143.693 12.1924 143.897 12.3212 144.12 12.407C144.343 12.4928 144.582 12.5337 144.821 12.5273V12.5273Z"
                    fill="#B2B2B2"
                />
                <path
                    opacity="0.5"
                    d="M105.744 12.5273L112.991 12.5273C113.231 12.521 113.469 12.5619 113.692 12.6476C113.916 12.7334 114.119 12.8622 114.291 13.0266C114.463 13.191 114.599 13.3875 114.693 13.6046C114.786 13.8217 114.834 14.055 114.834 14.2906C114.834 14.5263 114.786 14.7596 114.693 14.9767C114.599 15.1938 114.463 15.3903 114.291 15.5547C114.119 15.719 113.916 15.8479 113.692 15.9337C113.469 16.0194 113.231 16.0603 112.991 16.054L110.12 16.054C109.652 16.0664 109.208 16.2576 108.882 16.5869C108.556 16.9163 108.373 17.3577 108.373 17.8173C108.373 18.2769 108.556 18.7184 108.882 19.0477C109.208 19.377 109.652 19.5683 110.12 19.5807L132.474 19.5807C132.713 19.587 132.952 19.5461 133.175 19.4603C133.398 19.3746 133.602 19.2457 133.774 19.0813C133.946 18.917 134.082 18.7204 134.175 18.5033C134.269 18.2862 134.317 18.053 134.317 17.8173C134.317 17.5817 134.269 17.3484 134.175 17.1313C134.082 16.9142 133.946 16.7177 133.774 16.5533C133.602 16.3889 133.398 16.2601 133.175 16.1743C132.952 16.0885 132.713 16.0476 132.474 16.054L128.665 16.054C128.198 16.0416 127.754 15.8504 127.428 15.521C127.102 15.1917 126.919 14.7502 126.919 14.2906C126.919 13.831 127.102 13.3896 127.428 13.0603C127.754 12.7309 128.198 12.5397 128.665 12.5273L139.622 12.5273C140.089 12.5149 140.533 12.3237 140.859 11.9944C141.186 11.665 141.368 11.2236 141.368 10.764C141.368 10.3044 141.186 9.86293 140.859 9.53359C140.533 9.20425 140.089 9.01303 139.622 9.00064L105.746 9.00064C105.279 9.01303 104.835 9.20425 104.509 9.53359C104.182 9.86292 104 10.3044 104 10.764C104 11.2236 104.182 11.665 104.509 11.9944C104.835 12.3237 105.279 12.5149 105.746 12.5273L105.744 12.5273Z"
                    fill="#B2B2B2"
                />
            </g>
            <path
                d="M151 13H149.95M139.634 21H127.672M121.822 21H122.756M145.188 13H116"
                stroke="#129188"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M105 57.5003C105 59.0105 104.715 60.5059 104.163 61.9011C103.61 63.2963 102.8 64.564 101.778 65.6318C100.757 66.6997 99.5442 67.5467 98.2097 68.1246C96.8752 68.7026 95.4448 69 94.0003 69C91.083 69 88.2851 67.7885 86.2221 65.6319C84.1592 63.4753 83.0002 60.5503 83 57.5003C83 54.4502 84.159 51.5251 86.2219 49.3684C88.2849 47.2116 91.0829 46 94.0003 46C96.9177 46.0002 99.7155 47.2119 101.778 49.3686C103.841 51.5253 105 54.4504 105 57.5003V57.5003Z"
                fill="#E4F2EC"
            />
            <g opacity="0.5">
                <path
                    opacity="0.5"
                    d="M12.7825 59.58H11.8385C11.3464 59.5934 10.8789 59.7988 10.5355 60.1527C10.1921 60.5066 10 60.981 10 61.4749C10 61.9687 10.1921 62.4431 10.5355 62.797C10.8789 63.1509 11.3464 63.3564 11.8385 63.3697H12.7825C13.0348 63.3765 13.2859 63.3326 13.521 63.2404C13.7561 63.1483 13.9704 63.0098 14.1513 62.8332C14.3322 62.6565 14.4759 62.4453 14.5741 62.212C14.6723 61.9788 14.7229 61.7281 14.7229 61.4749C14.7229 61.2216 14.6723 60.971 14.5741 60.7377C14.4759 60.5044 14.3322 60.2932 14.1513 60.1166C13.9704 59.94 13.7561 59.8015 13.521 59.7093C13.2859 59.6172 13.0348 59.5732 12.7825 59.58V59.58Z"
                    fill="#B2B2B2"
                />
                <path
                    opacity="0.5"
                    d="M53.9265 59.58H46.296C46.0437 59.5869 45.7925 59.5429 45.5574 59.4507C45.3223 59.3586 45.108 59.2201 44.9271 59.0435C44.7463 58.8669 44.6025 58.6557 44.5043 58.4224C44.4062 58.1891 44.3556 57.9384 44.3556 57.6852C44.3556 57.432 44.4062 57.1813 44.5043 56.948C44.6025 56.7147 44.7463 56.5035 44.9271 56.3269C45.108 56.1503 45.3223 56.0118 45.5574 55.9196C45.7925 55.8275 46.0437 55.7835 46.296 55.7904H49.319C49.8111 55.777 50.2786 55.5716 50.622 55.2177C50.9654 54.8638 51.1575 54.3894 51.1575 53.8955C51.1575 53.4017 50.9654 52.9273 50.622 52.5734C50.2786 52.2195 49.8111 52.014 49.319 52.0007H25.7831C25.5308 51.9939 25.2797 52.0378 25.0446 52.13C24.8095 52.2221 24.5951 52.3606 24.4143 52.5372C24.2334 52.7139 24.0896 52.9251 23.9915 53.1583C23.8933 53.3916 23.8427 53.6423 23.8427 53.8955C23.8427 54.1488 23.8933 54.3994 23.9915 54.6327C24.0896 54.866 24.2334 55.0772 24.4143 55.2538C24.5951 55.4304 24.8095 55.5689 25.0446 55.6611C25.2797 55.7532 25.5308 55.7972 25.7831 55.7904H29.7928C30.2849 55.8037 30.7524 56.0092 31.0957 56.3631C31.4391 56.7169 31.6313 57.1913 31.6313 57.6852C31.6313 58.1791 31.4391 58.6534 31.0957 59.0073C30.7524 59.3612 30.2849 59.5667 29.7928 59.58H18.257C17.7649 59.5933 17.2975 59.7988 16.9541 60.1527C16.6107 60.5066 16.4186 60.981 16.4186 61.4749C16.4186 61.9687 16.6107 62.4431 16.9541 62.797C17.2975 63.1509 17.7649 63.3564 18.257 63.3697H53.9237C54.4158 63.3564 54.8833 63.1509 55.2267 62.797C55.57 62.4431 55.7622 61.9687 55.7622 61.4749C55.7622 60.981 55.57 60.5066 55.2267 60.1527C54.8833 59.7988 54.4158 59.5933 53.9237 59.58H53.9265Z"
                    fill="#B2B2B2"
                />
            </g>
            <path
                d="M24 67H32"
                stroke="#129188"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M28 71V63"
                stroke="#129188"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M98.6643 51.3421C98.3859 51.2009 98.0776 51.1275 97.765 51.1279C97.3684 51.1294 96.9809 51.2457 96.65 51.4625C96.3191 51.6792 96.0592 51.9871 95.9021 52.3483L93.0015 58.7129L92.5554 57.8926C92.3828 57.5676 92.1248 57.295 91.8086 57.1038C91.4925 56.9126 91.13 56.8099 90.7598 56.8066C90.3958 56.8075 90.0391 56.9071 89.728 57.0945C88.7594 57.6764 88.4182 58.9716 88.9682 59.9818L91.3688 64.3977C91.5401 64.723 91.7975 64.9958 92.1133 65.187C92.4291 65.3781 92.7914 65.4804 93.1614 65.4827H93.2378C93.6206 65.4656 93.9909 65.3422 94.3065 65.1267C94.6222 64.9111 94.8705 64.612 95.0232 64.2633L99.6247 54.1728C100.103 53.1222 99.6726 51.8553 98.6643 51.3441V51.3421Z"
                fill="#129188"
            />
            <path
                d="M101.381 64.8584C99.7326 66.4907 97.565 67.5057 95.2471 67.7305C92.9292 67.9554 90.6045 67.3761 88.6689 66.0914C86.7334 64.8067 85.3068 62.896 84.6321 60.685C83.9575 58.4739 84.0767 56.0993 84.9692 53.9656C85.8618 51.8319 87.4726 50.0712 89.5272 48.9834C91.5817 47.8957 93.953 47.5482 96.2369 48.0001C98.5208 48.4521 100.576 49.6755 102.053 51.4621C103.529 53.2486 104.335 55.4876 104.334 57.7977C104.337 59.1099 104.078 60.4096 103.571 61.6216C103.064 62.8336 102.319 63.9337 101.381 64.8584V64.8584ZM94.2623 44.5906C86.9209 44.5906 80.9485 50.5158 80.9485 57.7977C80.9525 61.2989 82.3565 64.6556 84.8525 67.1314C87.3484 69.6071 90.7325 70.9998 94.2623 71.0038C101.604 71.0038 107.577 65.0796 107.577 57.7977C107.577 50.5158 101.604 44.5906 94.2623 44.5906Z"
                fill="#129188"
            />
            <path
                d="M71.3817 30.8297H95.573C96.6577 30.8297 97.5388 29.8872 97.5388 28.7284C97.5388 27.5697 96.6577 26.6271 95.573 26.6271H71.3817C70.297 26.6271 69.416 27.5697 69.416 28.7284C69.416 29.8872 70.297 30.8297 71.3817 30.8297Z"
                fill="#129188"
            />
            <path
                d="M64.2859 25.9178C63.771 25.9176 63.2675 26.0689 62.8393 26.3526C62.411 26.6363 62.0772 27.0395 61.88 27.5114C61.6828 27.9833 61.6312 28.5026 61.7316 29.0036C61.832 29.5046 62.08 29.9648 62.4441 30.326C62.8083 30.6872 63.2722 30.9331 63.7773 31.0327C64.2824 31.1323 64.8059 31.0811 65.2816 30.8855C65.7574 30.69 66.1639 30.3589 66.4499 29.9341C66.7359 29.5093 66.8884 29.0099 66.8882 28.4991C66.8882 27.8145 66.614 27.1579 66.126 26.6739C65.638 26.1898 64.9761 25.9178 64.2859 25.9178V25.9178Z"
                fill="#129188"
            />
            <path
                d="M64.2859 38.5825C63.771 38.5823 63.2676 38.7336 62.8394 39.0172C62.4111 39.3008 62.0773 39.7041 61.8801 40.1759C61.6829 40.6477 61.6312 41.1669 61.7315 41.6679C61.8319 42.1689 62.0797 42.6291 62.4438 42.9903C62.8078 43.3515 63.2717 43.5976 63.7767 43.6973C64.2817 43.797 64.8052 43.7459 65.2809 43.5505C65.7567 43.3551 66.1633 43.0241 66.4494 42.5995C66.7355 42.1748 66.8882 41.6755 66.8882 41.1648C66.8882 40.4801 66.6141 39.8234 66.1261 39.3392C65.6381 38.855 64.9762 38.5828 64.2859 38.5825V38.5825Z"
                fill="#129188"
            />
            <path
                d="M64.2859 51.2482C63.771 51.248 63.2675 51.3993 62.8393 51.6829C62.411 51.9666 62.0772 52.3699 61.88 52.8418C61.6828 53.3136 61.6312 53.8329 61.7316 54.3339C61.832 54.8349 62.08 55.2951 62.4441 55.6563C62.8083 56.0175 63.2722 56.2635 63.7773 56.3631C64.2824 56.4627 64.8059 56.4115 65.2816 56.2159C65.7574 56.0203 66.1639 55.6892 66.4499 55.2644C66.7359 54.8396 66.8884 54.3402 66.8882 53.8294C66.8882 53.1448 66.614 52.4883 66.126 52.0042C65.638 51.5201 64.9761 51.2482 64.2859 51.2482V51.2482Z"
                fill="#129188"
            />
            <path
                d="M86.6653 7.89083L97.3656 18.5046H89.5079C88.7542 18.5038 88.0317 18.2065 87.4988 17.6779C86.9658 17.1492 86.6661 16.4325 86.6653 15.685V7.89083ZM79.3321 62.962L79.1753 62.5236H59.942C59.4228 62.5228 58.925 62.3178 58.5579 61.9537C58.1908 61.5895 57.9842 61.0959 57.9834 60.5809V7.70493C57.9839 7.18976 58.1904 6.69581 58.5576 6.33144C58.9247 5.96706 59.4226 5.76199 59.942 5.76119H82.9773V15.685C82.9792 17.4024 83.6678 19.049 84.8922 20.2634C86.1165 21.4778 87.7765 22.1609 89.5079 22.1627H99.2162V43.0832L99.644 43.2438C100.528 43.5775 101.381 43.9897 102.19 44.4753L103.197 45.0815V19.1946L85.8617 2H60.3901C59.7107 1.99972 59.0357 2.10749 58.3908 2.31923C57.1729 2.66782 56.1076 3.41078 55.3648 4.42968C54.4766 5.54195 53.9954 6.92042 54 8.33938V59.9464C53.9958 61.3653 54.477 62.7436 55.3648 63.8561C56.1082 64.8742 57.1733 65.6167 58.3908 65.9655C59.0357 66.1773 59.7107 66.2851 60.3901 66.2848H80.9505L80.4025 65.3079C79.9815 64.5568 79.6244 63.7721 79.3352 62.962H79.3321Z"
                fill="#129188"
            />
            <path
                d="M87.0625 43.9025C89.2448 42.8036 91.659 42.2341 94.1064 42.2407C94.9963 42.2411 95.8845 42.3162 96.7616 42.4649L97.5642 42.6013L97.5357 41.7931C97.497 40.6586 96.6343 39.7726 95.5731 39.7726H71.3817C70.297 39.7726 69.416 40.7152 69.416 41.8739C69.416 43.0327 70.297 43.9753 71.3817 43.9753H86.9219L87.0625 43.9046V43.9025Z"
                fill="#129188"
            />
            <path
                d="M70.1729 53.8294C70.1729 54.9346 71.0152 55.8347 72.051 55.8347H78.5052L78.5551 55.2286C78.6256 54.3576 78.7766 53.495 79.0063 52.6514L79.2314 51.822H72.051C71.0152 51.822 70.1729 52.7212 70.1729 53.8274V53.8294Z"
                fill="#129188"
            />
        </svg>
    );
}
